import React, { useState, useEffect } from "react";
import toaster, { Theme } from "@saleshandy/designs/lib/toaster";
import { Modal } from "react-bootstrap";
import { Cross, Wallet } from "@saleshandy/icons";
import { Button, SkeletonLoading } from "@saleshandy/design-system";
import StripeCheckout from "react-stripe-checkout";

import type { IProps } from "./purchase-ev-credits-modal-container";
import { EVCreditsPlan } from "../../../components/billing/types";
import { executeOnRequestStatus } from "../../utils";
import { Images } from "../../utils/app-constants";
import { accessibleOnClick } from "../../utils/accessible-on-click";
import { getIsRequestPending } from "../../utils/get-request-status";
import { handle3dSecureCardPayment } from "../handle-3d-secure-card-payment/handle3dSecureCardPayment";

import Checkbox from "../checkbox";
import EvCreditsPlans from "./components/ev-credits-plans";

const PurchaseCreditsModal: React.FC<IProps> = ({
  show,
  onClose,
  availableCredits,

  evCreditsPlans,
  getEVCreditsPlanRequestStatus,
  getEVCreditsPlanRequestError,
  sendGetEVCreditsPlanRequest,
  resetGetEVPlanRequest,

  purchaseEVCreditsRequestStatus,
  purchaseEVCreditsRequestError,
  sendPurchaseEVCreditsRequest,
  resetPurchaseEVCreditsRequest,

  purchaseEVCreditsResponse,
  resetPurchaseEVCreditsResponse,

  sendGetEVCreditsRequest,
}) => {
  const [selectedPlan, setSelectedPlan] = useState<EVCreditsPlan>(null);

  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [is3dSecureResponseStatePending, setIs3dSecureResponseStatePending] =
    useState<boolean>(false);
  const [isEVCreditsPaymentFailed, setIsEVCreditsPaymentFailed] =
    useState<boolean>(false);

  const handleCheckBox = () => {
    setIsChecked((preState) => !preState);
  };

  const handleSelectPlan = (plan: EVCreditsPlan) => {
    setSelectedPlan(plan);
  };

  const onGetToken = (token) => {
    sendPurchaseEVCreditsRequest({
      stripeCardToken: token.id,
      planId: +selectedPlan.id,
    });
  };

  const handle3dSecureCard = async (response) => {
    const apiEndPoint =
      "/agency/email-verification-credits/confirm-payment-intent";
    setIs3dSecureResponseStatePending(true);

    const { isError, serverResponse } = await handle3dSecureCardPayment({
      response,
      apiEndPoint,
    });

    setIs3dSecureResponseStatePending(false);

    if (isError) {
      setIsEVCreditsPaymentFailed(true);
    } else {
      toaster.success(serverResponse.payload.message, { theme: Theme.New });

      sendGetEVCreditsRequest();
      resetPurchaseEVCreditsResponse();
      onClose();
    }
  };

  useEffect(() => {
    if (show) {
      sendGetEVCreditsPlanRequest();
    }
  }, [show]);

  useEffect(() => {
    executeOnRequestStatus({
      status: getEVCreditsPlanRequestStatus,
      onSuccess: () => {
        setSelectedPlan(() =>
          evCreditsPlans?.find((plan) => plan.isBestValue === 1)
        );
        resetGetEVPlanRequest();
      },
      onFailed: () => {
        if (getEVCreditsPlanRequestError?.message) {
          toaster.error(getEVCreditsPlanRequestError.message, {
            theme: Theme.New,
          });
        }
      },
    });
  }, [getEVCreditsPlanRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: purchaseEVCreditsRequestStatus,
      onSuccess: () => {
        if (purchaseEVCreditsResponse.requires_action) {
          handle3dSecureCard(purchaseEVCreditsResponse);
          resetPurchaseEVCreditsRequest();
        } else {
          toaster.success(purchaseEVCreditsResponse.message, {
            theme: Theme.New,
          });
          sendGetEVCreditsRequest();
          resetPurchaseEVCreditsRequest();
          onClose();
        }
      },
      onFailed: () => {
        setIsEVCreditsPaymentFailed(true);
        if (purchaseEVCreditsRequestError?.message) {
          toaster.error(purchaseEVCreditsRequestError.message, {
            theme: Theme.New,
          });
        }
      },
    });
  }, [purchaseEVCreditsRequestStatus]);

  const isPurchasingEvCredits = getIsRequestPending(
    purchaseEVCreditsRequestStatus
  );
  const isLoading = getIsRequestPending(getEVCreditsPlanRequestStatus);

  return (
    <Modal
      show={show}
      backdrop="static"
      className="purchase-credits-modal p-5"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="d-flex justify-content-between purchase-credits-modal-header">
          <div className="left">
            <span>
              <Wallet />
            </span>
            <span>Purchase Credits</span>
          </div>

          <div
            role="button"
            className="close-btn-wrap"
            {...accessibleOnClick(onClose)}
          >
            <Cross />
          </div>
        </div>

        {isEVCreditsPaymentFailed ? (
          <div
            className="payment-failed"
            {...accessibleOnClick(() => setIsEVCreditsPaymentFailed(false))}
          >
            <p>Payment Failed</p>
            <Button>Retry</Button>
          </div>
        ) : (
          <div className="credits-plan-wrap">
            <>
              <EvCreditsPlans
                plans={evCreditsPlans}
                selectedPlan={selectedPlan}
                handleSelectPlan={handleSelectPlan}
                availableCredits={availableCredits}
                isLoading={isLoading}
              />

              {isLoading ? (
                <div className="d-flex agreement-loader">
                  <SkeletonLoading width={16} height={16} />
                  <div className="ml-1">
                    <SkeletonLoading width={366} height={16} />
                    <SkeletonLoading width={150} height={16} />
                  </div>
                </div>
              ) : (
                <Checkbox
                  disabled={false}
                  label="By purchasing credits, I agree that email verification credits are non-refundable."
                  intermediate={false}
                  checked={isChecked}
                  onChange={handleCheckBox}
                  className="purchase-ev-credits-agreement"
                />
              )}
            </>
          </div>
        )}
      </Modal.Body>

      <div className="purchase-credits-modal-footer">
        <p className="selected-plan-amount">
          {selectedPlan ? `$${selectedPlan?.amount}` : ""}
        </p>

        <StripeCheckout
          image={Images.SHLogoIcon}
          name="Saleshandy"
          amount={selectedPlan?.amount * 100}
          billingAddress
          zipCode
          token={onGetToken}
          stripeKey={process.env.REACT_APP_STRIPE_KEY}
        >
          <Button
            disabled={
              isPurchasingEvCredits ||
              is3dSecureResponseStatePending ||
              !isChecked ||
              isLoading
            }
            isLoading={isPurchasingEvCredits || is3dSecureResponseStatePending}
            className="buy-now-btn"
            loadingText="Buying..."
          >
            Buy Now
          </Button>
        </StripeCheckout>
      </div>
    </Modal>
  );
};

export default PurchaseCreditsModal;
