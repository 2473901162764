import React from "react";
import { SkeletonLoading } from "@saleshandy/design-system";
import { accessibleOnClick } from "../../../utils/accessible-on-click";
import { EVCreditsPlan } from "../../../../components/billing/types";

type IProps = {
  plans: EVCreditsPlan[];
  selectedPlan: EVCreditsPlan;
  availableCredits: number;
  handleSelectPlan: (plan: EVCreditsPlan) => void;
  isLoading: boolean;
};

const EvCreditsPlans: React.FC<IProps> = ({
  plans,
  selectedPlan,
  handleSelectPlan,
  isLoading,
}) => {
  const renderLoader = () => {
    const arr = Array.from({ length: 8 }, (_, i) => i + 1);
    return arr.map((key) => (
      <div key={key} className="credit-plan loading">
        <SkeletonLoading width={44} height={20} />
      </div>
    ));
  };

  const renderPlans = () =>
    plans.map((credit) => {
      const isSelected = credit.id === selectedPlan?.id;
      const creditPlanClass = `credit-plan ${isSelected ? "selected" : ""}`;
      return (
        <div
          key={credit.id}
          className={creditPlanClass}
          {...accessibleOnClick(() => handleSelectPlan(credit))}
        >
          {credit.isBestValue === 1 && (
            <div className="best-value-badge">Best Value</div>
          )}
          <p className="plan-name">{credit.name.replace(" Credits", "")}</p>
        </div>
      );
    });

  return <>{isLoading ? renderLoader() : renderPlans()}</>;
};

export default EvCreditsPlans;
