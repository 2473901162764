import React, { useState } from "react";
import { Button } from "@saleshandy/design-system";
import type { IProps } from "./ev-credits-details-container";
import { supportUrls } from "../../../../shared/utils/url";
import PurchaseCreditsModal from "../../../../shared/components/purchase-ev-credits-modal";
import EVCreditsLoader from "./ev-credits-loader";
import { getIsRequestPending } from "../../../../shared/utils/get-request-status";
import { AgencyRole } from "../../../../shared/enum";

const CheckIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.1999 5.66325L6.42692 10.4633L4.79993 8.82707"
      stroke="#202020"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const EVCreditsDetails: React.FC<IProps> = ({
  availableCredits,
  getEVCreditsRequestStatus,
  role,
}) => {
  const [purchaseEvCreditsModal, setPurchaseEvCreditsModal] = useState(false);

  const onShowPurchaseEvCreditsModal = () => {
    setPurchaseEvCreditsModal(true);
  };

  const onHidePurchaseEvCreditsModal = () => {
    setPurchaseEvCreditsModal(false);
  };

  return (
    <div className="ev-credits-container">
      {getIsRequestPending(getEVCreditsRequestStatus) ? (
        <EVCreditsLoader />
      ) : (
        <>
          <div className="card">
            <div className="card-body">
              <div className="ev-credits-data">
                <span className="semibold-1 mb-1">Available Credits</span>
                <span className="available-credits">{availableCredits}</span>
                <span className="regular-1 gray-txt-12">
                  For more Information, Please visit Email Verification{" "}
                  <a
                    href={supportUrls.faqUrl}
                    target="_blank"
                    className="faq-page-link"
                    rel="noreferrer"
                  >
                    FAQ Page
                  </a>
                </span>
              </div>
              {role === AgencyRole.AgencyOwner && (
                <Button onClick={onShowPurchaseEvCreditsModal}>
                  Purchase Credits
                </Button>
              )}
            </div>
            <hr className="custom-hr" />
            <div className="enhanced-email-verification">
              <span className="bold-text">Enhanced email verification. </span>
              <span className="normal-text">
                Along with valid emails, get insights that will boost your
                deliverability like:
              </span>
              <ul className="features-list">
                <li>
                  <CheckIcon />
                  <span className="feature-text">Verification sub status</span>
                </li>
                <li>
                  <CheckIcon />
                  <span className="feature-text">MX records</span>
                </li>
                <li>
                  <CheckIcon />
                  <span className="feature-text">Free email detection</span>
                </li>
              </ul>
            </div>
          </div>

          {role === AgencyRole.AgencyOwner && (
            <PurchaseCreditsModal
              show={purchaseEvCreditsModal}
              onClose={onHidePurchaseEvCreditsModal}
              availableCredits={availableCredits}
            />
          )}
        </>
      )}
    </div>
  );
};

export default EVCreditsDetails;
